(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/skeleton-wrapper/assets/javascripts/skeleton-config.js') >= 0) return;  svs.modules.push('/components/sportinfo/skeleton-wrapper/assets/javascripts/skeleton-config.js');
"use strict";


const {
  SkeletonConfigKey
} = svs.components.sportinfo.skeletonWrapper;
const SkeletonConfig = {
  [SkeletonConfigKey.MATCH_INFO]: {
    minHeight: 580,
    skeletonConfig: [{
      height: 0,
      numLines: 1,
      width: 170,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320,
      className: 'margin-bottom-1'
    }, {
      height: 0,
      numLines: 1,
      width: 150,
      className: 'margin-bottom-1'
    }, {
      height: 20,
      numLines: 1,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320
    }]
  },
  [SkeletonConfigKey.TT_NEWS]: {
    minHeight: 580,
    skeletonConfig: [{
      height: 0,
      numLines: 1,
      width: 170,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320,
      className: 'margin-bottom-1'
    }, {
      height: 0,
      numLines: 1,
      width: 150,
      className: 'margin-bottom-1'
    }, {
      height: 20,
      numLines: 1,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320
    }]
  },
  [SkeletonConfigKey.MATCH_LINEUPS]: {
    minHeight: 560,
    skeletonConfig: [{
      height: 0,
      numLines: 1,
      width: 170,
      className: 'margin-bottom-1'
    }, {
      height: 0,
      numLines: 1,
      numCols: 2,
      width: 230,
      className: 'margin-bottom-1'
    }, {
      height: 0,
      numLines: 1,
      width: 320,
      className: 'margin-bottom-1'
    }, {
      height: 21,
      numLines: 1
    }]
  },
  [SkeletonConfigKey.HEAD_TO_HEAD]: {
    minHeight: 800,
    skeletonConfig: [{
      height: 0,
      numLines: 1,
      width: 170,
      className: 'margin-bottom-1'
    }, {
      height: 2,
      numCols: 3,
      numLines: 2,
      width: 100,
      className: 'margin-bottom-1'
    }, {
      height: 2,
      numCols: 3,
      numLines: 1,
      width: 160,
      className: 'margin-bottom-1'
    }, {
      height: 4,
      numCols: 3,
      numLines: 1,
      width: 160,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numCols: 2,
      numLines: 1,
      width: 80,
      className: 'margin-bottom-1'
    }, {
      height: 3,
      numLines: 1,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numCols: 2,
      numLines: 1,
      width: 80,
      className: 'margin-bottom-1'
    }, {
      height: 3,
      numLines: 1
    }]
  },
  [SkeletonConfigKey.LIVE_TABLE]: {
    minHeight: 580,
    skeletonConfig: [{
      height: 0,
      numLines: 1,
      width: 170,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320,
      className: 'margin-bottom-1'
    }, {
      height: 0,
      numLines: 1,
      width: 150,
      className: 'margin-bottom-1'
    }, {
      height: 20,
      numLines: 1,
      className: 'margin-bottom-1'
    }, {
      height: 1,
      numLines: 1,
      width: 320
    }]
  },
  DEFAULT: {
    minHeight: 200,
    skeletonConfig: [{
      height: 3,
      numLines: 1
    }]
  }
};
setGlobal('svs.components.sportinfo.skeletonWrapper.SkeletonConfig', SkeletonConfig);

 })(window);