(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/skeleton-wrapper/assets/javascripts/skeleton-wrapper.js') >= 0) return;  svs.modules.push('/components/sportinfo/skeleton-wrapper/assets/javascripts/skeleton-wrapper.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
const {
  Skeleton,
  SkeletonCircle
} = svs.components.lbUi;
const SkeletonWrapper = _ref => {
  let {
    config
  } = _ref;
  return React.createElement("div", {
    className: "margin-1"
  }, Array.isArray(config) && config.length > 0 && config.map((cfg, i) => {
    if (cfg.size > 0) {
      return React.createElement(SkeletonCircle, _extends({}, cfg, {
        key: i
      }));
    } else if (cfg.numLines > 0) {
      return React.createElement(Skeleton, _extends({}, cfg, {
        key: i
      }));
    }
    return null;
  }));
};
setGlobal('svs.components.sportinfo.skeletonWrapper.SkeletonWrapper', SkeletonWrapper);

 })(window);